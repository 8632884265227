.messages-list-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-width: 250px;
    min-height: 250px; */
    position: relative;
}
.messages-list{
    width: 100%;
    height: 100%;
    padding: 1rem;
}