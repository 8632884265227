.leaderboard-item-container{
    z-index: 0;
    position: relative;
    min-height: 240px;
    min-width: 280px;
    max-width: 290px;
    /* padding: 1rem; */
    /* margin: 1rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.top-users-container{
    /* -webkit-box-shadow: 0px 0px 29px -17px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 29px -17px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 29px -17px rgba(0,0,0,0.75); */
    /* background: rgb(253,105,93);
    background: linear-gradient(0deg, rgba(253,105,93,1) 0%, rgba(255,97,88,1) 100%); */
    border-radius: 8px;
    width: 100%;
    min-height:200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.hexagon {
    position: absolute;
    z-index: -1;
    width: 250px; 
    height: 144.34px;
    background-color: transparent;
    margin: 72.17px 0;
    border-left: solid 4px #333333;
    border-right: solid 4px #333333;
  }
  
  .hexagon:before,
  .hexagon:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 176.78px;
    height: 176.78px;
    -webkit-transform: scaleY(0.5774) rotate(-45deg);
    -ms-transform: scaleY(0.5774) rotate(-45deg);
    transform: scaleY(0.5774) rotate(-45deg);
    background-color: inherit;
    left: 32.6117px;
  }
  
  .hexagon:before {
    top: -88.3883px;
    border-top: solid 5.6569px #333333;
    border-right: solid 5.6569px #333333;
  }
  
  .hexagon:after {
    bottom: -88.3883px;
    border-bottom: solid 5.6569px #333333;
    border-left: solid 5.6569px #333333;
  }