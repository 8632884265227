#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.title {
  color: #1890FF;
  font-size: 18px;
  font-weight: bold;
}


.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  margin: 20% auto 10% auto;
}

.logo img {
  background-color: #001529;
  width: 100%;
  height: 100%;
}

.site-layout-sub-header-background {
  background: #001529 !important;
  text-align: left;
}

.ant-layout-sider {
  background-color: #001529 !important;
}

.site-layout-background {
  /* background: #fff; */
}

.page-container {
  height: 100vh;
  width: 100vw;
}

.ant-layout-sider-children {
  height: 100vh !important;
}

.ant-layout-header {
  background-color: #ffffff !important;
}

.ant-menu-submenu-title {
  background-color: #0c2238;
}

.ant-menu-item {
  /* border-radius: 10px; */
  /* margin-left: 5% !important; */
  width: 100% !important;
  background-color: transparent !important;
  color: white !important;
}

.ant-menu-item a {
  color: white !important;
}

.ant-menu-item-selected a {
  color: white !important;
  font-weight: bold;
}

.ant-menu {
  background-color: #001529 !important;
  color: white !important;
}

.ant-menu-item-selected {
  background: rgb(24, 144, 255);
  background: linear-gradient(90deg, rgba(24, 144, 255, 1) 0%, rgba(0, 21, 41, 1) 100%);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.header-secondary {
  text-align: center;
  padding: 2%;
  font-weight: bold;
  font-size: 20px;
}

.ant-col {
  margin: auto !important;
  margin-bottom: 2% !important;
  margin-top: 2% !important;
}

.ant-card {
  margin: auto !important;
}

.progress {
  position: absolute;
  top: 45%;
  left: 50%;
}

.purchases-container {
  height: 400px;
  overflow-y: scroll;
}

.purchase-details p {
  margin: 2%;
  font-weight: bold;
}

.box-shadow {
  background-color: white !important;
  box-shadow:
    0 7px 30px -10px rgba(150, 170, 180, 0.5);
  border-radius: 8px;
}

.green-gradiant {
  color: white !important;
  /* 
  color: rgb(51, 203, 85) !important;
  color: linear-gradient(180deg, rgba(51, 203, 85, 1) 0%, rgba(42, 174, 72, 1) 100%) !important; */
}

.red-gradiant {

  color: white !important;
  /* color: rgb(227, 94, 107) !important;
  color: linear-gradient(180deg, rgba(227, 94, 107, 1) 0%, rgba(220, 53, 69, 1) 100%) !important; */
}

.blue-gradiant {
  color: white !important;
  /* color: rgb(30, 198, 225) !important;
  color: linear-gradient(180deg, rgba(30, 198, 225, 1) 0%, rgba(24, 167, 190, 1) 100%) !important; */
}

.yellow-gradiant {
  /* color: rgb(255, 205, 54) !important;
  color: linear-gradient(180deg, rgba(255, 205, 54, 1) 0%, rgba(255, 194, 12, 1) 100%) !important; */
  color: white !important;

}

.ant-modal-title {
  color: white !important;
}

#user-box {
  cursor: pointer;
}

.patch-editor-wrapper {
  border: solid #f0f2f5 1px;
}

.patch-editor {
  padding: 2%;
}

.grid-games {
  margin: 0 !important;
}

.ant-form-item-required {
  font-weight: bold;
  color: black !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.filters-section div {
  margin: 5px;
}

.filters-section {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.history-information {
  margin: auto;
}

.chart-container {
  background: rgb(24, 144, 255);
  background: linear-gradient(180deg,
      rgba(24, 144, 255, 1) 0%,
      rgba(37, 76, 136, 1) 100%);
  -webkit-box-shadow: 0px 0px 19px -3px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: 0px 0px 19px -3px rgba(0, 0, 0, 0.38);
  box-shadow: 0px 0px 19px -3px rgba(0, 0, 0, 0.38);
  border-radius: 10px;
  padding: 5%;
  color: white;
  font-weight: bold;
}

.site-back-top-basic {
  color: rgba(64, 64, 64, 0.6);
}

.chart {}

.games-list-item {
  background-color: white;
  /* border-radius: 5px; */
  transition: background-color 0.5s;
}

.games-list-item:hover {
  cursor: pointer;
  background-color: #cdd1d6;
}

.ant-modal-header {
  background-color: #001529 !important;
  color: white !important;
}

.ant-modal-close-x {
  color: white !important;
}

.shadow-background {
  -webkit-box-shadow: 0px 0px 34px -17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 34px -17px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 34px -17px rgba(0, 0, 0, 0.75);
}

@media only screen and (max-width: 720px) {
  .progress {
    position: absolute;
    top: 40%;
    left: 35%;
  }

  .chart-container {
    background: rgb(24, 144, 255);
    background: linear-gradient(180deg,
        rgba(24, 144, 255, 1) 0%,
        rgba(37, 76, 136, 1) 100%);
    -webkit-box-shadow: 0px 0px 19px -3px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 0px 0px 19px -3px rgba(0, 0, 0, 0.38);
    box-shadow: 0px 0px 19px -3px rgba(0, 0, 0, 0.38);
    border-radius: 0px;
    padding: 0%;
    color: white;
    font-weight: bold;
  }

  .chart {}
}