.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    background-color: #F8F9F9;
}

.login-form {
    /*background-color: #37404F;*/
    /*-webkit-box-s hadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);*/
    /*-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);*/
    /*box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);*/
    padding: 2% !important;
    /*border-radius: 2px;*/
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.ant-form-item-required {
    color: #1890ff !important;
}

.login-button {
    margin-bottom: 0% !important;
}

.login-logo {
    width: 100%;
}

@media only screen and (max-width: 720px) {
    .login-form {
        padding: 6% !important;
        border-radius: 2px;
    }

    .login-logo {
        width: 100%;
    }
}