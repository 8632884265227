.notifications-list{
    padding:'1rem'
}

.notification-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}
.notification-item div{
    margin: 0.5rem;
}